import { ListSubheader, List } from "@material-ui/core";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import {
  MenuItem,
  menuItemListAdmin,
  menuItemListNonAdmin,
  menuItemListNoRole,
  menuItemListDeveloper,
} from "./items";
import { experimentalStyled } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import { ReactComponent as MotorsIcon } from "./motorsIcons.svg";
import { ReactComponent as UseCaseIcon } from "./useCaseIcons.svg";
import { ReactComponent as eamIcon } from "./eamIcon.svg";
import { ReactComponent as eamSchedulerIcon } from "./eamSchedulerIcon.svg";
import { ReactComponent as AILEMO } from "./AirLeak.svg";
import { ReactComponent as CCP } from "./Aseptic-CCP.svg";
import { ReactComponent as CIP } from "./Aseptic-CIP.svg";
import { ReactComponent as Transmitters } from "./Aseptic-Batch-Room.svg";
import { ReactComponent as Valves } from "./Aseptic-Valves.svg";
import { ReactComponent as UTIL } from "./Utilities.svg";
import { ReactComponent as MOAP } from "./Motors-and-pumps.svg";
import { ReactComponent as ServoDrives } from "./Servo-Drives.svg";
import { ReactComponent as RHBP } from "./Run-Hours.svg";
import { ReactComponent as WQMS } from "./WQMS.svg";
import { ReactComponent as SupplyChain } from "./supply-chain.svg";
import { ReactComponent as IA } from "./inject_icon.svg";
import { ReactComponent as AGRIcon } from "./AGRIcon.svg";

import { object } from "prop-types";


const ListWrapper = experimentalStyled(List)(
  ({ theme }) => `
        padding:0px;
`
);

const renderSidebarMenuItems: any = ({
  items,
  path,
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <>{items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}</>
);

const reduceChildRoutes: any = ({
  ev,
  path,
  item,
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        heading={item.heading}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
        heading={item.heading}
      />
    );
  }
  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const usersList = useSelector((state: any) => state.usersList);
  const levelsList = useSelector((state: any) => state.levelsList);
  const usecaseList = useSelector((state: any) => state.usecaseList);
  const seedsList = useSelector((state: any) => state.seedsList);
  const satPlantsList = useSelector((state: any) => state.satPlantsList);
  // const defaultPlant =
  //   satPlantsList.satPlants.length > 0 ? satPlantsList.satPlants[0] : "";
  let filteredMenuItemListAdmin = menuItemListAdmin;
  let filteredmenuItemListNonAdmin = menuItemListNonAdmin;

  const currentPermssion = seedsList.seeds.find(
		(ele: any) => ele.seed_key_name === 'EAMRoleConfigData'
	)?.seed_data_value

  const satConfigSeed = seedsList.seeds.find((ele: any) => {
    return ele.seed_key_name === "SATConfigData";
  })?.seed_data_value;
  let accessible_usecases: any;
  //To hide tickets page from the side menu
  // let accountPermissionData: any =
  //   usersList.currentAccountPermissions?.length > 0
  //     ? usersList.currentAccountPermissions[0]
  //     : {};
  // if (accountPermissionData && Object.keys(accountPermissionData).length > 0) {
  //   if (
  //     !accountPermissionData?.role_permissions_allocated["alerts"]["tickets"][
  //       "Permission"
  //     ].read
  //   ) {
  //     filteredMenuItemListAdmin = menuItemListAdmin.filter((itemData: any) => {
  //       let isticketItem = true;
  //       itemData.items.forEach((innerItems: any) => {
  //         if (innerItems.name === "Tickets") {
  //           isticketItem = false;
  //           return;
  //         }
  //       });
  //       if (isticketItem) {
  //         return itemData;
  //       }
  //     });
  //     filteredmenuItemListNonAdmin = menuItemListNonAdmin.filter(
  //       (itemData: any) => {
  //         let isticketItem = true;
  //         itemData.items.forEach((innerItems: any) => {
  //           if (innerItems.name === "Tickets") {
  //             isticketItem = false;
  //             return;
  //           }
  //         });
  //         if (isticketItem) {
  //           return itemData;
  //         }
  //       }
  //     );
  //   }
  // }
  if (
    usersList.currentUserPermissions.includes("ARCH_ADMIN") ||
    usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
    usersList.currentUserPermissions.includes("ARCH_DEVELOPER")
  ) {
    accessible_usecases = usecaseList.usecases;
  } else {
    accessible_usecases = usecaseList.usecases?.filter((usecase: any) => {
      if (
        levelsList.uniqueUsecases.includes(usecase?._id) ||
        usecase?.routes_use_code === "downtime-request"
      ) {
        return usecase;
      }
    });
  }

  const usecasesIcon = {
    "aseptic-valves": Valves,
    "air-leaks-monitoring": AILEMO,
    "aseptic-ccp": CCP,
    "aseptic-cip": CIP,
    "aseptic-transmitters": Transmitters,
    "motors-and-pumps": MOAP,
    "run-hours": RHBP,
    "run-hours-pm": RHBP,
    "servo-drives": ServoDrives,
    utilities: UTIL,
    wqms: WQMS,
    // "downtime-request" : SupplyChainTickets,
    // "injection-analytics" : IA,
    "injection-analytics": IA,
    "downtime-request": SupplyChain,
    "inspection-system": AGRIcon,
  };

  const eamSchedulerArray = [
    {
      name: "EAM Prod Overlay",
      link: "",
      icon: eamIcon,
      heading: true,
    },
    {
      name: "Scheduler",
      link: "/eam-prod-overlay/scheduler",
      icon: eamSchedulerIcon,
    },
  ];

  const usecaseArray = [
    {
      name: "Usecase",
      link: "",
      icon: UseCaseIcon,
      heading: true,
    },
    ...accessible_usecases
      .filter((ele: any) => {
        return ele.level_use_code !== "NA" && ele.level_use_code !== "EAM";
      })
      .map((ele: any) => {
        return {
          name: ele.use_case_alias,
          link: `/usecase/${ele.routes_use_code}`,
          icon: usecasesIcon[ele.routes_use_code as keyof typeof usecasesIcon],
          // icon: MotorsIcon,
        };
      }),
  ];

  const satArray = [
    {
      name: "SAT",
      link: "",
      icon: UseCaseIcon,
      heading: true,
    },
    ...satPlantsList.satPlants
      .filter((ele: any) => {
        return satConfigSeed
          ?.map((plant: any) => {
            return plant.key;
          })
          .includes(ele);
      })
      ?.map((item: any) => {
        return {
          name: `Live SAT ${item}`,
          link: `/sat/sat-current/${item}`,
          icon: MotorsIcon,
        };
      }),
  ];

  const eamSchedulerList = [{ items: eamSchedulerArray }];
  const usecaseItemList = [{ items: usecaseArray }];
  const satItemList = [{ items: satArray }];

  const isEamPlantManagerRole = (permissions: any) => {
    const allHaveName = permissions?.filter(
      (user: any) => !currentPermssion?.includes(user)
    );
    return allHaveName.every(
      (item: any) =>
        item.includes("ARCH_USECASE_EAM") || item.includes("ARCH_USECASE_EAM_")
    );
  };

  /**
   * fucntion to check user has only downtime usecase roles.
   * @param menuItems
   * @returns
   */
  const checkUserHasOnlyDowntimeUsecaseAccess = (menuItems: any) => {
    const nonAdminItems = menuItems;
    const userRoles = usersList.currentUserPermissions;
    const downtimeUseCaseRoles = [
      "ARCH_SCM_NPI_ANALYST",
      "ARCH_SCM_CORE_MASTER_PLANNER",
      "ARCH_SCM_FG_PLANNER",
      "ARCH_SCM_SNP_MANAGER",
      "ARCH_SCM_BRAND_MANAGER",
      "ARCH_SCM_CORE_ANALYST",
      "ARCH_SCM_FG_MANAGER",
      "ARCH_SCM_BRAND_PLANNER",
      "ARCH_SCM_PLANT_DIRECTOR",
      "ARCH_SCM_ADMIN",
    ];
    let hasOnlyDowntimeUsecaseRole = false;
    for (let index = 0; index < userRoles.length; index++) {
      const element = userRoles[index];
      if (downtimeUseCaseRoles.includes(element)) {
        hasOnlyDowntimeUsecaseRole = true;
      } else {
        hasOnlyDowntimeUsecaseRole = false;
        break;
      }
    }

    if (hasOnlyDowntimeUsecaseRole) {
      nonAdminItems.splice(1, 1);
    }
    return isEamPlantManagerRole(usersList.currentUserPermissions)
      ? []
      : nonAdminItems;
  };

  /**
   * fucntion to check user has only CIP usecase roles.
   * @param menuItems
   * @returns
   */
  const checkUserHasCIPUsecaseAccess = (menuItems: any) => {
    var nonAdminItems = menuItems;
    const userRoles = usersList.currentUserPermissions.filter((ele: any) => {
      return ele.startsWith("ARCH_USECASE_");
    });
    const CIPUseCaseRoles = [
      "ARCH_USECASE_CIP", //adding cip usecase champion since alert module should be hidden for cip usecase champion
    ];
    let hasOnlyCIPUsecaseRole = false;
    for (let index = 0; index < userRoles.length; index++) {
      const element = userRoles[index];
      if (CIPUseCaseRoles.includes(element)) {
        hasOnlyCIPUsecaseRole = true;
      } else {
        hasOnlyCIPUsecaseRole = false;
        break;
      }
    }

    if (hasOnlyCIPUsecaseRole) {
      return nonAdminItems.filter((ele: any) => {
        return ele.items[0].name !== "Alerts";
      });
    } else {
      return nonAdminItems;
    }
  };

  return (
    <>
      {usersList.currentUserPermissions.length === 0 ? (
        <>
          {menuItemListNoRole.map((section) => (
            <ListWrapper>
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname,
              })}
            </ListWrapper>
          ))}
        </>
      ) : usersList.currentUserPermissions.includes("ARCH_ADMIN") ? (
        // Added custom Scrollbar to side bar
        <CustomScrollbars>
          {usecaseItemList.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {eamSchedulerList.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {satItemList.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {filteredMenuItemListAdmin.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {/* <div
            style={{
              textAlign: "center",
              position: "absolute",
              width: "100%",
              bottom: "2em",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `${env.baseURL_monarch.substring(
                  0,
                  env.baseURL_monarch.indexOf("/api/")
                )}`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            <img
              src={`${window.location.origin + "/MonARCH.svg"}`}
              style={{
                width: "12em",
                alignSelf: "center",
                backgroundColor: "#232A58",
                padding: "0.5em 1.5em",
                borderRadius: "0.8em",
              }}
              alt="Niagara | MonARCH"
            />
            {env.environment !=="PROD" ?
            <><hr style={{ borderTop: "1px solid #6d6d6d", margin: "10px 0" }} />
            <small>
              Build # <b>{env.Azure_DevOps_Build_Number}</b>
            </small></>
            :<></> }
          </div> */}
        </CustomScrollbars>
      ) : usersList.currentUserPermissions.includes("ARCH_DEVELOPER") ? (
        <CustomScrollbars>
          {usecaseItemList.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {eamSchedulerList.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
          {menuItemListDeveloper.map((section: any) => {
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
        </CustomScrollbars>
      ) : usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
        usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER") ||
        usersList.currentUserPermissions.some((ele: any) => {
          return ele.includes("ARCH_PM_");
        }) ||
        usersList.currentUserPermissions.some((ele: any) => {
          return ele.includes("ARCH_USECASE_");
        }) ||
        usersList.currentUserPermissions.some((ele: any) => {
          return ele.includes("ARCH_SCM_");
        }) ? (
        <>
          {usecaseItemList.map((section) => {
            //handle usecase champion rolee
            let usecaseCode = "ARCH_USECASE_";
            let useCaseMenu = section.items.filter((menu: any) => {
              usecaseCode = "ARCH_USECASE_" + menu?.item_code;
              if (menu.item_code == undefined) {
                return true;
              } else if (
                (usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_PM_");
                }) ||
                  usersList.currentUserPermissions.includes(
                    "ARCH_ASSET_MANAGER"
                  )) &&
                (usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_USECASE_");
                }) ||
                  usersList.currentUserPermissions.some((ele: any) => {
                    return ele.includes("ARCH_SCM_");
                  }))
              ) {
                if (
                  usersList.currentUserPermissions.includes(usecaseCode) ||
                  usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
                ) {
                  return true;
                }
              } else if (
                usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_PM_");
                }) ||
                usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
                usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
              ) {
                return true;
              } else {
                if (
                  usersList.currentUserPermissions.includes(usecaseCode) ||
                  usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
                ) {
                  return true;
                }
              }
            });
            section.items = useCaseMenu.length > 1 ? [...useCaseMenu] : [];
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}

          {(usersList.currentUserPermissions.includes("ARCH_USECASE_ALL") ||
            usersList.currentUserPermissions.includes("ARCH_USECASE_EAM") ||
            usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
            usersList.currentUserPermissions.some((item: any) =>
              currentPermssion?.includes(item)
            )) &&
            eamSchedulerList.map((section: any) => {
              return (
                <ListWrapper>
                  {renderSidebarMenuItems({
                    items: section.items,
                    path: location.pathname,
                  })}
                </ListWrapper>
              );
            })}

          {/* For rendering live SATs for plant manager role when usecase champion role is not present */}
          {usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_PM_");
          }) &&
          !isEamPlantManagerRole(usersList.currentUserPermissions) &&
          !usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_USECASE_SAT");
          }) &&
          !usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_USECASE_ALL");
          }) &&
          satItemList[0].items.filter((ele: any) => {
            return (
              ele.name === "SAT" ||
              usersList.currentUserPermissions
                .filter((ele: any) => {
                  return ele.includes("ARCH_PM_");
                })
                .map((plant: any) => {
                  return plant.substring(8, plant.length);
                })
                .includes(ele.name.substring(9, ele.length))
            );
          }).length > 1 ? (
            satItemList.map((section: any) => {
              return (
                <ListWrapper>
                  {renderSidebarMenuItems({
                    items: section.items.filter((ele: any) => {
                      return (
                        ele.name === "SAT" ||
                        usersList.currentUserPermissions
                          .filter((ele: any) => {
                            return ele.includes("ARCH_PM_");
                          })
                          .map((plant: any) => {
                            return plant.substring(8, plant.length);
                          })
                          .includes(ele.name.substring(9, ele.length))
                      );
                    }),
                    path: location.pathname,
                  })}
                </ListWrapper>
              );
            })
          ) : (
            <></>
          )}
          {/* For rendering live SATs for SAT usecase champion when plant manager role is present */}
          {usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_PM_");
          }) &&
          (usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_STAKEHOLDER");
          }) ||
            usersList.currentUserPermissions.some((ele: any) => {
              return ele.includes("ARCH_USECASE_SAT");
            }) ||
            usersList.currentUserPermissions.some((ele: any) => {
              return ele.includes("ARCH_USECASE_ALL");
            })) ? (
            <>
              {satItemList.map((section: any) => {
                return (
                  <ListWrapper>
                    {renderSidebarMenuItems({
                      items: section.items.filter((ele: any) => {
                        return (
                          ele.name === "SAT" ||
                          usersList.currentUserPermissions
                            .filter((ele: any) => {
                              return ele.includes("ARCH_PM_");
                            })
                            .map((plant: any) => {
                              return plant.substring(8, plant.length);
                            })
                            .includes(ele.name.substring(9, ele.length))
                        );
                      }),
                      path: location.pathname,
                    })}
                  </ListWrapper>
                );
              })}
            </>
          ) : (
            <></>
          )}
          {/* For rendering live SATs for SAT usecase champion when plant manager role is not present */}
          {!usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_PM_");
          }) &&
          (usersList.currentUserPermissions.some((ele: any) => {
            return ele.includes("ARCH_STAKEHOLDER");
          }) ||
            usersList.currentUserPermissions.some((ele: any) => {
              return ele.includes("ARCH_USECASE_SAT");
            }) ||
            usersList.currentUserPermissions.some((ele: any) => {
              return ele.includes("ARCH_USECASE_ALL");
            })) ? (
            <>
              {satItemList.map((section: any) => {
                return (
                  <ListWrapper>
                    {renderSidebarMenuItems({
                      items: section.items,
                      path: location.pathname,
                    })}
                  </ListWrapper>
                );
              })}
            </>
          ) : (
            <></>
          )}
          {checkUserHasCIPUsecaseAccess(
            checkUserHasOnlyDowntimeUsecaseAccess(filteredmenuItemListNonAdmin)
          ).map((section: any) => {
            //handle usecase champion rolee
            let usecaseCode = "ARCH_USECASE_";
            let useCaseMenu = section.items.filter((menu: any) => {
              usecaseCode = "ARCH_USECASE_" + menu?.item_code;
              if (menu.item_code == undefined) {
                return true;
              } else if (
                (usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_PM_");
                }) ||
                  usersList.currentUserPermissions.includes(
                    "ARCH_ASSET_MANAGER"
                  )) &&
                usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_USECASE_");
                })
              ) {
                if (
                  usersList.currentUserPermissions.includes(usecaseCode) ||
                  usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
                ) {
                  return true;
                }
              } else if (
                usersList.currentUserPermissions.some((ele: any) => {
                  return ele.includes("ARCH_PM_");
                }) ||
                usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
                usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
              ) {
                return true;
              } else {
                if (
                  usersList.currentUserPermissions.includes(usecaseCode) ||
                  usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
                ) {
                  return true;
                }
              }
            });
            section.items = [...useCaseMenu];
            return (
              <ListWrapper>
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname,
                })}
              </ListWrapper>
            );
          })}
        </>
      ) : (
        // For rednering SAT menu for SAT Admin and SAT usecase Champion
        <></>
      )}
    </>
  );
}

export default SidebarMenu;
