import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import { ReactComponent as ExportIcon } from "./Icons/export-icon.svg";
import { ReactComponent as ImportIcon } from "./Icons/import-icon.svg";
import { ReactComponent as CreateButton } from "./Icons/create-button-icon.svg";
import { ReactComponent as CreateIcon } from "./Icons/create-ticket-icon.svg";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../../../App";
import { ReactComponent as DownIcon } from "./Icons/down-icon.svg";
import { useNavigate } from "react-router-dom";
import { Asset_Usecase } from "../../../../models/Types_Interfaces";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from "xlsx";
import { dashboardStyles } from "./DowntimeLandingPageStyles";
import AutoCompleteDropdownWithTextField from "./Components/AutoCompleteDropdown";
import TabsControl from "./Components/TabsControl";
import SearchField from "./Components/SearchField";
import CreateUpdateTicket from "./Components/CreateUpdateTicket";
import NpiDowmtimeTickets from "./Components/NpiDowntimeTickets";
import ConfirmAlertDialog from "./Components/ConfirmAlertDialog";
import PopOverItems, { popOverItem } from "./Components/PopOverItems";
import Axios from "../../../../Utils/AxiosConfig/axiosConfig";

import {
  createDownTimeTicket,
  getAllDownTimeTickets,
  getFiltersList
} from "./Services/SupplyChainTikcetService";
import AvailablePlants from "./Components/AvailablePlants";
import { downtimeTicketStatusOptions, getImportTicketsOption } from "./Helpers";
import ReplayIcon from "@mui/icons-material/Replay";
import { saveAs } from "file-saver";
import ImportTickets from "./Components/ImportTickets";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ITicket {
  dt_ticket_alias: string;
  dt_ticket_type: string;
  created_on: string;
  assignee_name: string;
  dt_ticket_priority: string;
  dt_ticket_status: string;
  dt_ticket_open_duration_timestamp: string;
  dt_end_date: string;
  dt_start_date: string;
  master_planner_confirmed_timestamp: string;
  finished_goods_planner_confirmed_timestamp: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={props.value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {props.value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DowntimeLadingPage = (props: any) => {
  const appContextProps: any = useContext(AppContext); //consuming context created in App.tsx
  const navigate = useNavigate();
  const availablePlants = AvailablePlants();
  const classes = dashboardStyles();
  const plantsList = useSelector((state: any) => state.plantsList);
  const levelsList = useSelector((state: any) => state.levelsList);
  const usersList = useSelector((state: any) => state.usersList);
  const usecaseList = useSelector((state: any) => state.usecaseList);
  const [selectedPlant, setSelectedPlant] = React.useState<any>(null);
  const [selectedType, setSelectedType] = React.useState<any>(null);
  const [selectedStatus, setSelectedStatus] = React.useState<any>(null);
  const [selectedPriority, setSelectedPriority] = React.useState<any>(null);
  const downtimeState = useSelector((state: any) => state.downtime);
  const [selectedVendor, setSelectedVendor] = React.useState<any>(null);
  const [selectedManufRegion, setSelectedManufRegion] =
    React.useState<any>(null);
  const [selectedSupplyChail, setSelectedSupplyChail] =
    React.useState<any>(null);
  const [selectedBookmark, setSelectedBookmark] = React.useState<any>(null);
  const [tabsObject, setTabsObject] = useState({
    ...downtimeState.tabOptions
  });

  const [createTicketDialog, updateCreateTicketDialog] = useState(false);
  const [currentUsecase, setCurrentUsecase] = React.useState<any>([]); //fetching and storing current usecase details through url
  const [filterMessage, setFilterMessage] = React.useState<any>({
    useCaseId: "",
    plantId: ""
  });
  const [ticketCreatedDialog, setTicketCreatedDialog] = useState(false);
  const [anchorElDotPopUp, setAnchorElDotPopUp] =
    React.useState<HTMLButtonElement | null>(null);

  const openAnchorElDotPopUp = Boolean(anchorElDotPopUp);

  const [paginationOptions, setPaginationOptions] = useState({
    page: 1,
    limit: 5
  });

  const [downtimeTicketsObject, updateDowntimeTicketsObject] = useState({
    tickets: [],
    total: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(0);
  const [debouncedAssigneeQuery, setDebouncedAssigneeQuery] = React.useState(0);
  const [ticketIdSearchText, setTicketIdSearchText] =
    React.useState<string>("");
  const [ticketAssigneeSearchText, setTicketAssigneeSearchText] =
    React.useState<string>("");
  const [sortingColumn, setSortingColumn]: any = useState({});
  const [downTimeFilters, setDownTimeFilters]: any = useState({
    type: [],
    category: [],
    status: [],
    priority: [],
    vendor: [],
    manufacturing_region: [],
    supply_chain_region: []
  });
  const [importDialog, setImportDialog] = useState<boolean>(false);
  const currentUser = usersList.currentUser;
  const dispatch = useDispatch();
  const tabOptions = [
    {
      label: "Overhaul and Diagnostic Visit",
      key: "overhaul_and_diagnostic_visit",
      category: "OH"
    },
    // {
    //   label: "NPI Downtime Tickets",
    //   key: "npi_downtime_tickets",
    //   category: "NPI"
    // },
    {
      label: "Plant Director Downtime Tickets",
      key: "plant_director_downtime_tickets",
      category: "PD"
    }
  ];

  const exportTicketsToCSV = async () => {
    const payload = await formPayload(true);

    const url = await getAllDownTimeTickets(payload, true);

    const res = await Axios.get(url, {
      responseType: "arraybuffer"
    });

    // const res: any = await getDownTimeTickets(payload);
    const blob = new Blob([res?.data], {
      type: "application/octet-stream"
    });

    const buff = saveAs(blob, `DowntimeTickets.xlsx`);
    return buff;
  };

  const handlePopOverItemAction = (item: popOverItem) => {
    if (item.key === "export_ticket") {
      exportTicketsToCSV();
    } else if (item.key === "import_ticket") {
      setImportDialog(true);
    }
    // Add import condition once implemented
  };

  const formPopOverItems = () => {
    const popOverItems: any = [
      {
        label: "Export File",
        key: "export_ticket",
        icon: <ExportIcon />,
        disabled:
          applyPagination(
            downtimeTicketsObject.tickets,
            paginationOptions.page,
            paginationOptions.limit
          ).length < 1
            ? true
            : false
      }
    ];
    if (tabsObject?.category === "OH") {
      popOverItems.push({
        label: "Import File",
        key: "import_ticket",
        icon: <ImportIcon />,
        disabled: getImportTicketsOption(
          usersList?.currentUserPermissions || []
        )
      });
    }
    return popOverItems;
  };

  const handleTicketCreated = async (formData: any): Promise<void> => {
    try {
      const createTicketPayload: any = {
        ...formData,
        dt_ticket_category: tabsObject.category,
        currentUserId: usersList.currentUser?._id
      };
      const response = await createDownTimeTicket(createTicketPayload);
      if (response) {
        // update new created ticket redux state.
        // setTicketCreatedDialog(true);
        getDownTimeTickets();
        dispatch({
          type: "LOAD_SNACKBAR",
          payload: {
            message: "Ticket Created!",
            type: "success",
            open: true
          }
        });
      }
    } catch (error) {
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "Error while creating ticket",
          type: "error",
          open: true
        }
      });
      console.error(`Error:[Create ticket]:`, error);
    }
  };

  const handleTicketCreatedClose = () => {
    setTicketCreatedDialog(false);
  };

  const handleClose = () => {
    updateCreateTicketDialog(false);
  };

  const handleTabsChange = (
    event: React.SyntheticEvent,
    newValue: number,
    tabItem: any
  ) => {
    setPaginationOptions({
      page: 1,
      limit: 5
    });
    dispatch({
      type: "UPDATE_TAB_OPTIONS",
      payload: {
        tabValue: newValue,
        label: tabItem?.label,
        category: tabItem?.category
      }
    });
    setTabsObject({
      tabValue: newValue,
      label: tabItem?.label,
      category: tabItem?.category
    });
  };

  const handleClickOpen = () => {
    updateCreateTicketDialog(true);
  };

  //getting usecase from url
  const usecaseRoute = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.length
  );

  React.useEffect(() => {
    const tempUsecase = usecaseList.usecases.find((usecase: Asset_Usecase) => {
      return usecase.routes_use_code === "downtime-request";
    });
    setCurrentUsecase(tempUsecase);
    setFilterMessage({ ...filterMessage, useCaseId: tempUsecase?._id });
  }, [usecaseList.usecases, levelsList.levels, usersList.accessiblePlants]);

  // Using current plant code if available or defaultCode
  const defaultPlantCode = appContextProps.selectedPlantForCube || "HOU";

  React.useEffect(() => {
    if (plantsList.plants.length !== 0) {
      if (
        usersList.accessiblePlants.filter((ele: any) => {
          return ele.plant_code === defaultPlantCode;
        }).length > 0 &&
        availablePlants.filter((ele: any) => {
          return ele.plant_code === defaultPlantCode;
        }).length > 0
      ) {
        setSelectedPlant(
          plantsList.plants.find((plant: any) => {
            return plant.plant_code === defaultPlantCode;
          })
        );
        // setIsLoadingCubes(true);
      } else {
        if (
          usersList.accessiblePlants &&
          availablePlants.length > 0 &&
          usersList.accessiblePlants.filter((accessibePlant: any) => {
            return availablePlants
              .map((plant: any) => {
                return plant._id;
              })
              .includes(accessibePlant._id);
          }).length > 0
        ) {
          setSelectedPlant(
            usersList.accessiblePlants.filter((accessibePlant: any) => {
              return availablePlants
                .map((plant: any) => {
                  return plant._id;
                })
                .includes(accessibePlant._id);
            })[0]
          );
          //setting plant code for cube fetching of access token
          // setIsLoadingCubes(true);
          appContextProps.setSelectedPlantForCube(
            usersList.accessiblePlants.filter((accessibePlant: any) => {
              return availablePlants
                .map((plant: any) => {
                  return plant._id;
                })
                .includes(accessibePlant._id);
            })[0]?.plant_code
          );
        }
      }
    }
  }, [plantsList.plants, availablePlants]);

  React.useEffect(() => {
    //updating selected plant code everytime selected plant is modified
    if (selectedPlant !== null) {
      setFilterMessage({ ...filterMessage, plantId: selectedPlant._id });
      appContextProps.setSelectedPlantForCube(selectedPlant?.plant_code);
      getDownTimeFilters();
    }
  }, [selectedPlant]);

  const getDownTimeFilters = async () => {
    try {
      setIsLoading(true);
      const response = await getFiltersList(selectedPlant?._id);
      if (response && response?.data) {
        setDownTimeFilters({
          ...response.data
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const formPayload = async (excel?: boolean): Promise<any> => {
    let payload: any = {
      page: paginationOptions.page,
      limit: paginationOptions.limit,
      dt_ticket_category: tabsObject.category,
      plant_id: selectedPlant._id,
      dt_requestor_id: currentUser?._id
    };

    if (ticketIdSearchText.length > 0) {
      payload.dt_ticket_alias = ticketIdSearchText;
    }
    if (ticketAssigneeSearchText.length > 0) {
      payload.dt_assignee_name = ticketAssigneeSearchText;
    }

    if (selectedType && selectedType?.name) {
      payload.dt_ticket_type = selectedType?.name;
    }
    if (selectedStatus && selectedStatus?.name) {
      payload.dt_ticket_status = selectedStatus?.name;
    }

    if (selectedManufRegion && selectedManufRegion?.name) {
      payload.dt_manufacturing_region = selectedManufRegion?.name;
    }
    if (selectedSupplyChail && selectedSupplyChail?.name) {
      payload.dt_supply_chain_region = selectedSupplyChail?.name;
    }
    if (selectedVendor && selectedVendor?.name) {
      payload.dt_vendor = selectedVendor?.name;
    }
    if (selectedPriority && selectedPriority?.name) {
      payload.dt_ticket_priority = selectedPriority?.name;
    }

    if (excel) {
      payload.excel = true;
    }

    if (Object.keys(sortingColumn).length > 0) {
      const sortColumnName = Object.keys(sortingColumn);
      const sortColunmOrder = Object.values(sortingColumn);
      payload.sort_by = sortColumnName[0];
      payload.sort_order = sortColunmOrder[0];
    }
    return payload;
  };

  const getDownTimeTickets = async (excel?: boolean): Promise<void> => {
    try {
      setIsLoading(true);
      if (
        tabsObject &&
        tabsObject?.category &&
        selectedPlant &&
        selectedPlant?._id
      ) {
        const payload = await formPayload();
        const response = await getAllDownTimeTickets(payload);
        if (response && response?.data) {
          updateDowntimeTicketsObject({
            tickets: response?.data?.rows,
            total: response?.data.total
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "Error while fetching tickets",
          type: "error",
          open: true
        }
      });
      console.error(`Error:[getDownTimeTickets]:`, error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDownTimeTickets();
  }, [
    tabsObject,
    selectedPlant,
    paginationOptions,
    selectedType,
    selectedManufRegion,
    selectedSupplyChail,
    debouncedSearchQuery,
    sortingColumn,
    selectedVendor,
    selectedPriority,
    selectedStatus,
    debouncedAssigneeQuery
  ]);

  React.useEffect(() => {
    // Use a timer to debounce the search query updates
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchQuery(ticketIdSearchText.length);
    }, 500);

    // Cleanup the timer on every searchQuery change
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [ticketIdSearchText]);

  React.useEffect(() => {
    // Use a timer to debounce the search query updates
    const debounceTimer = setTimeout(() => {
      setDebouncedAssigneeQuery(ticketAssigneeSearchText.length);
    }, 500);

    // Cleanup the timer on every searchQuery change
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [ticketAssigneeSearchText]);

  const handleClickDotPupUp = (e: any) => {
    setAnchorElDotPopUp(e.currentTarget);
  };
  const handleCloseDotPopUp = () => {
    setAnchorElDotPopUp(null);
  };

  const applyPagination = (
    ticketList: any,
    page: number,
    limit: number
  ): any => {
    return ticketList.slice(0, limit);
  };
  return (
    <>
      {levelsList.loading ? (
        <CircularProgress style={{ margin: "20% 45%" }} />
      ) : (
        <Box className={classes.criticalControlSec}>
          <Grid
            container
            direction="row"
            justifyContent="between"
            alignItems="left"
            spacing={2}
            rowSpacing={4}
            style={{
              padding: "40px 20px 20px"
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                {/* Plant Selection */}
                <AutoCompleteDropdownWithTextField
                  className={`${classes.autoDropdown} ${classes.templateDropdown}`}
                  options={availablePlants.map((plant: any) => {
                    return plant;
                  })}
                  value={selectedPlant}
                  handleChange={(event: any, newValue: any) => {
                    setSelectedVendor(null);
                    props.setSelectedPlant(newValue);
                    setSelectedPlant(newValue);
                    appContextProps.setSelectedPlantForCube(
                      newValue.plant_code
                    );
                  }}
                  icon={<DownIcon />}
                  placeholder={"Select Plant"}
                  optionLabel={"plant_name"}
                  showLabel={false}
                  style={{minWidth: 'max-content'}}
                />

                <Box style={{ marginLeft: "-20%" }}>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ fontSize: "12px" }}
                  >
                    {selectedPlant?.plant_name} Downtime Requests
                  </Typography>

									<Typography
										variant='h2'
										textAlign='center'            
										sx={{
											fontSize: {
												xs: '0.6rem',
												sm: '0.8rem',
												md: '1.2rem',
												lg: '1.2rem'
											}
										}}
									>
                    {tabsObject.label}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    style={{
                      textAlign: "center",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginTop: "25px"
                    }}
                    onClick={() => {
                      navigate(`alerts`);
                    }}
                  >
                    Alerts
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* Handling tabs */}
              <TabsControl
                tabValue={tabsObject.tabValue}
                handleChange={(event, newValue, tabItem) => {
                  // Setting Up vendor data is null whenever changing category tab.
                  setSelectedVendor(null);
                  handleTabsChange(event, newValue, tabItem);
                }}
                options={tabOptions}
                className={classes.tabsControl}
              />
            </Grid>

          <Grid md={12} mt="15px" ml="15px" display='flex'  
                sx={{
                flexDirection: { xs: "column", sm: "column", md:"column", lg:"row" },
                alignItems: { xs: "end", sm: "end", md:"start", lg:"start" },
                justifyContent:"flex-start",
                gap:"15px",
                flexWrap: "auto"
                }}>
						<Grid item xs={12} sm={12} md={10}
							display='flex'
              sx={{
                flexDirection: { xs: "column", sm: "row", md:"row", lg:"row" },
                alignItems: { xs: "start", sm: "start" },
                justifyContent: "flex-start",
                gap: "10px",
                }}
						>
                {/* Search field */}
                <SearchField
                  className={`${classes.multiDropdown} ${classes.searchBox}`}
                  defaultValue={""}
                  handleChange={(event: any) => {
                    setTicketIdSearchText(event.target.value);
                  }}
                  placeholder={"Ticket ID"}
                />
                {/* Priority dropdown Selection */}
                {(tabsObject?.category === "NPI" ||
                  tabsObject?.category === "PD") && (
                  <AutoCompleteDropdownWithTextField
                    className={classes.autoDropdown}
                    options={downTimeFilters.priority}
                    value={selectedPriority}
                    handleChange={(event: any, newValue: any) => {
                      setSelectedPriority(newValue);
                    }}
                    icon={<DownIcon />}
                    placeholder={"Priority"}
                    optionLabel="name"
                    showLabel={false}
                    style={{ minWidth: '100px' }}
                  />
                )}

                {/* Type dropdown Selection */}
                <AutoCompleteDropdownWithTextField
                  className={classes.autoDropdown}
                  options={downTimeFilters.type}
                  value={selectedType}
                  handleChange={(event: any, newValue: any) => {
                    setSelectedType(newValue);
                  }}
                  icon={<DownIcon />}
                  placeholder={"Type"}
                  optionLabel="name"
                  showLabel={false}
                  style={{ minWidth: '100px' }}
                />

                {tabsObject?.category === "OH" && (
                  <>
                    <AutoCompleteDropdownWithTextField
                      className={classes.autoDropdown}
                      options={downTimeFilters?.vendor}
                      value={selectedVendor}
                      handleChange={(event: any, newValue: any) => {
                        setSelectedVendor(newValue);
                      }}
                      icon={<DownIcon />}
                      placeholder={"Vendor"}
                      optionLabel="name"
                      showLabel={false}
                      style={{ minWidth: '100px' }}
                    />
                  </>
                )}
                {/* Ticket status Selection */}
                <AutoCompleteDropdownWithTextField
                  className={classes.autoDropdown}
                  options={downtimeTicketStatusOptions}
                  value={selectedStatus}
                  handleChange={(event: any, newValue: any) => {
                    setSelectedStatus(newValue);
                  }}
                  icon={<DownIcon />}
                  placeholder={"Status"}
                  optionLabel="label"
                  showLabel={false}
                  style={{ minWidth: '100px' }}
                />

                {/* Search by assignee name */}
                <SearchField
                  className={`${classes.multiDropdown} ${classes.searchBox}`}
                  defaultValue={""}
                  handleChange={(event: any) => {
                    setTicketAssigneeSearchText(event.target.value);
                  }}
                  placeholder={"Assignee Name"}
                />
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => {
                    getDownTimeTickets();
                  }}
                  className={classes.searchBox}
                  style={{ minWidth: '80px',  height:'47px'}}
                  endIcon={<ReplayIcon />}
                >
                  Reload
                </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={2}
              className={`${classes.fliterMenus} ${classes.createBtn}`}
            >
              {tabsObject.category !== "OH" &&
                usersList.currentUserPermissions.some((ele: any) => {
                  return (
                    ele.includes("ARCH_ADMIN") ||
                    ele.includes("ARCH_SCM_ADMIN") ||
                    ele.includes("ARCH_PM_") ||
                    ele.includes("ARCH_SCM_NPI_ANALYST") ||
                    ele.includes("ARCH_SCM_PLANT_DIRECTOR")
                  );
                }) && (
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<CreateButton width="20" height="20"/>}
                    onClick={handleClickOpen}
                    className={classes.searchBox}
                    style={{ padding:"10px", height:"47px"}}
                  >
                    Create Ticket
                  </Button>
                )}

              {/* Commented export and import tickets */}
              <Box className={classes.MoreVertiIcon}>
                <IconButton
                  aria-controls={anchorElDotPopUp ? "long-menu" : undefined}
                  aria-expanded={anchorElDotPopUp ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickDotPupUp}
                  aria-label="Open to show more"
                  title="Open to show more"
                  disableRipple
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Grid>
            
          </Grid>

            {/* Export and import ticker popover */}
            <PopOverItems
              open={openAnchorElDotPopUp}
              anchorEl={anchorElDotPopUp}
              popOverItmes={formPopOverItems()}
              popOverItemAction={handlePopOverItemAction}
              closePopUp={handleCloseDotPopUp}
            />

            <Grid item xs={12} sm={12} md={12} className={classes.tableGrid}>
              <NpiDowmtimeTickets
                loading={isLoading}
                // applypagination is missed.
                tickets={applyPagination(
                  downtimeTicketsObject.tickets,
                  paginationOptions.page,
                  paginationOptions.limit
                )}
                totalCount={downtimeTicketsObject.total}
                page={paginationOptions.page - 1}
                limit={paginationOptions.limit}
                handlePageChange={(event: any, newPage: number) => {
                  setIsLoading(true);
                  setPaginationOptions({
                    ...paginationOptions,
                    page: newPage + 1
                  });
                }}
                handleLimitChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIsLoading(true);
                  setPaginationOptions({
                    ...paginationOptions,
                    limit: parseInt(event.target.value)
                  });
                }}
                handleColumsSort={(filter: any) => {
                  const sortColumn = filter.sort;
                  setSortingColumn({
                    ...sortColumn
                  });
                }}
                ticket_category={tabsObject.category}
              />

              {/* <CustomTabPanel value={tabsObject.tabValue} index={0}>
                Overhaul and Diagnostic Visiti tickets
              </CustomTabPanel>
              <CustomTabPanel
                value={tabsObject.tabValue}
                index={1}
              ></CustomTabPanel>
              <CustomTabPanel value={tabsObject.tabValue} index={2}>
                Plant Director Downtime Tickets
              </CustomTabPanel> */}
            </Grid>
          </Grid>

          {createTicketDialog && (
            <CreateUpdateTicket
              open={createTicketDialog}
              title={"Create Ticket"}
              primaryButtonText={"Save"}
              primaryAction={(formData: any) => {
                handleClose();
                handleTicketCreated(formData);
              }}
              secondaryButtonText={"Cancel"}
              secondaryAction={() => {
                handleClose();
              }}
              plantId={selectedPlant?._id}
            />
          )}
          <ConfirmAlertDialog
            open={ticketCreatedDialog}
            title="Ticket Created"
            secondaryTitle={"The ticket has been created in the Arch System"}
            primaryListTitle={"Ticket ID: HOU-OH-001"}
            primaryButtonText={"Go To Ticket"}
            primaryAcion={() => {
              navigate("list-details");
            }}
            secondaryButtonText={"Done"}
            secondaryAction={() => {
              handleTicketCreatedClose();
            }}
            icon={<CreateIcon />}
          />
          {importDialog && (
            <ImportTickets
              open={importDialog}
              onConfirm={async () => {
                setAnchorElDotPopUp(null);
                setImportDialog(false);
                await getDownTimeFilters();
                getDownTimeTickets();
              }}
              onClose={() => {
                setAnchorElDotPopUp(null);
                setImportDialog(false);
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default DowntimeLadingPage;
