import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  SET_CURRENT_USER,
  SET_CURRENT_USER_SUCCESS,
  SET_CURRENT_USER_IMAGE,
  SET_CURRENT_USER_IMAGE_SUCCESS,
  SET_CURRENT_USER_PERMISSIONS,
  SET_CURRENT_USER_PERMISSIONS_SUCCESS,
  SET_CURRENT_ACCOUNT_PERMISSIONS,
  SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS,
  SET_ACCESSIBLE_PLANTS,
  SET_ACCESSIBLE_PLANTS_SUCCESS,
  STORE_ALL_USERS_IMAGE,
  STORE_ALL_USERS_IMAGE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_ERROR,
  SET_CURRENT_USER_ACTIONS,
  SET_SCM_USER_PLANT,
  SET_SCM_USER_PLANT_SUCCESS,
  SET_SCM_USER_PLANT_ERROR
} from "../Types/users.constants";

const INITIAL_STATE = {
  loading: false,
  users: [],
  error: null,
  currentUser: {},
  currentUserImage: {},
  currentUserPermissions: [],
  currentAccountPermissions: [],
  accessiblePlants: [],
  currentUserActions: {},
  scmUserPlantGroup: []
};

export const usersListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, loading: true, users: [], currentUser: {} };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case LOAD_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_CURRENT_USER:
      return { ...state, loading: true, currentUser: {} };
    case SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload
      };
    case SET_CURRENT_USER_PERMISSIONS:
      return { ...state, loading: true, currentUserPermissions: [] };
    case SET_CURRENT_ACCOUNT_PERMISSIONS:
      return { ...state, loading: true, currentAccountPermissions: [] };
    case SET_CURRENT_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUserPermissions: action.payload
      };
    case SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAccountPermissions: action.payload
      };
    case SET_CURRENT_USER_ACTIONS:
      return {
        ...state,
        loading: false,
        currentUserActions: action.payload
      };
    case SET_ACCESSIBLE_PLANTS:
      return { ...state, loading: true, accessiblePlants: [] };
    case SET_ACCESSIBLE_PLANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accessiblePlants: action.payload
      };
    case SET_CURRENT_USER_IMAGE:
      return { ...state, currentUserImage: {} };
    case SET_CURRENT_USER_IMAGE_SUCCESS:
      return {
        ...state,
        currentUserImage: action.payload
      };
    case STORE_ALL_USERS_IMAGE:
      return {
        ...state,
        loading: true
      };
    case STORE_ALL_USERS_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users:
          state.users.filter((ele: any) => {
            return ele._id === action.payload._id;
          }).length === 0
            ? [...state.users, action.payload]
            : [
                ...state.users.filter((ele: any) => {
                  return ele._id !== action.payload._id;
                }),
                action.payload
              ]
      };
    case UPDATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_CURRENT_USER:
      return { ...state, loading: true };
    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload
      };
    case UPDATE_CURRENT_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case SET_SCM_USER_PLANT:
      return { ...state, loading: true };
    case SET_SCM_USER_PLANT_SUCCESS:
      return { ...state, loading: false, scmUserPlantGroup: action.payload };
    case SET_SCM_USER_PLANT_ERROR:
      return { ...state, loading: false, scmUserPlantGroup: [] };

    default:
      return state;
  }
};
