import { FC, ReactNode, useState, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "../../contexts/SidebarContext";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { ListItemIcon } from "@material-ui/core";
import { ListItemButton, ListItemText, Theme } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { env } from "../../env";
import Axios from "../../Utils/AxiosConfig/axiosConfig";
import { useSelector } from "react-redux";
interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  heading?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  listButtonChild: {
    "&.MuiListItemButton-root": {
      padding: "10px 0 10px 50px",
      margin: "15px 5px",
      textDecoration: "none",
      color: "#E6E1E5",

      "&:hover": {
        background: "#232A5870",
        color: "#E6E1E5",
      },
    },

    "&.MuiListItemButton-root.Mui-selected": {
      background: "#232A58",
    },
  },
  listButtonParent: {
    "&.MuiListItemButton-root": {
      margin: "10px 5px",
      textDecoration: "none",
      color: "#E6E1E5",
      cursor: "default",

      "&:hover": {
        background: "#232A5870",
        color: "#E6E1E5",
      },
    },

    "&.MuiListItemButton-root.Mui-selected": {
      background: "#232A58",
    },

    "&.MuiListItemButton-root.Mui-disabled": {
      color: "#E6E1E5",
      opacity: 1,
    },
  },
  listIcon: {
    "&.MuiListItemIcon-root": {
      minWidth: "35px",
    },
  },
  typographyParent: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  typographyChild: {
    "&.MuiTypography-root": {
      fontSize: "1wpx",
      fontWeight: 500,
    },
  },
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  heading,
  ...rest
}) => {
  const [isHeading, setIsHeading] = useState<boolean>(
    heading && heading !== undefined ? true : false
  );
  const classes = useStyles();
  const userList = useSelector((state: any) => state.usersList);
  const { toggleSidebar } = useContext(SidebarContext);
  const location = useLocation();

  const handleUseCaseLog = (usecase: string) => {
    if (usecase !== "Motors and Pumps PdM") {
      Axios.post("usecase_activity_logs/insertUseCaseEventLogs", {
        user_id: userList.currentUser.display_name,
        usecase_type: usecase,
      });
    }
  };

  const isNewActive = link
    ? window.location.hash.includes("sat/sat-current") === true
      ? window.location.hash.substring(
        window.location.hash.lastIndexOf("/") + 1,
        window.location.hash.length
      ) === link?.substring(link.lastIndexOf("/") + 1, link.length)
      : window.location.hash.includes("maintenance") === true
        ? window.location.hash
          .split("/")[2]
          ?.startsWith(
            link.split("/")[2]?.substring(0, link.split("/")[2].length - 1)
          )
        : window.location.hash.split("/")[2] === link.split("/")[2]
    : false;

  return link?.includes("monarch") ? (
    <ListItemButton
      style={{ marginLeft: "-25px" }}
      component={RouterLink}
      to={location.pathname}
      key={name}
      {...rest}
      onClick={() => {
        toggleSidebar();
        window.open(`${env.monarch_app_url}`, "_blank", "noreferrer");
      }}
      className={`${!isHeading ? classes.listButtonChild : classes.listButtonParent
        }`}
      selected={isNewActive}
      disabled={isHeading}
      disableRipple
    >
      <ListItemIcon className={classes.listIcon}>
        {Icon && <Icon />}
      </ListItemIcon>
      <ListItemText
        primary={name}
        classes={{
          primary: isHeading
            ? classes.typographyParent
            : classes.typographyChild,
        }}
      />
    </ListItemButton>
  ) : (
    <ListItemButton
      component={RouterLink}
      to={link}
      key={name}
      {...rest}
      onClick={() => {
        toggleSidebar();
        handleUseCaseLog(name);
      }}
      className={`${!isHeading ? classes.listButtonChild : classes.listButtonParent
        }`}
      selected={isNewActive}
      disabled={isHeading}
      disableRipple
    >
      <ListItemIcon className={classes.listIcon}>
        {Icon && <Icon />}
      </ListItemIcon>
      <ListItemText
        style={{ marginLeft: `${name.includes("EAM") ? "0.8em" : ""}` }}
        primary={name}
        classes={{
          primary: isHeading
            ? classes.typographyParent
            : classes.typographyChild,
        }}
      />
    </ListItemButton>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
