import {
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_ERROR,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  UPDATE_MESSAGE_PAGINATION,
  UPDATE_MESSAGE_PAGINATION_SUCCESS,
  UPDATE_MESSAGE_PAGINATION_ERROR,
  LOAD_ALL_MESSAGES,
  LOAD_ALL_MESSAGES_SUCCESS,
  LOAD_ALL_MESSAGES_ERROR,
  UPDATE_MESSAGE_FILTER_PARAMS,
  UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS,
  UPDATE_MESSAGE_FILTER_PARAMS_ERROR,
} from "../Types/messages.contants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import store from "../store";
import { checkEmpty } from "../../Utils/UtilFunctions/Utils";

const PAGE_SIZE = 750;

const fetchPage = async (page: any, data: any) => {
  const useCaseId = data.payload?.useCaseId;
  const plantId = data.payload?.plantId;
  const assetId = data.payload?.assetId;
  const ticketId = data.payload?.ticketId;
  const created_on = data.payload?.created_on;

  let url = "";
  if (useCaseId !== undefined && plantId !== undefined) {
    //adding condition to fetch all messages for a specific ticket; as needed for CIP usecase
    if (checkEmpty(ticketId) && checkEmpty(created_on)) {
      url = `messages/filterMessagesByUsecasePlant?pageSize=${PAGE_SIZE}&page=${page}&usecaseId=${useCaseId}&plantId=${plantId}&assetId=${assetId}&ticketId=${ticketId}&created_on=${created_on}`;
    } else if (assetId !== "" && assetId !== undefined) {
      url = `messages/filterMessagesByUsecasePlant?pageSize=${PAGE_SIZE}&page=${page}&usecaseId=${useCaseId}&plantId=${plantId}&assetId=${assetId}`;
    } else {
      url = `messages/filterMessagesByUsecasePlant?pageSize=${PAGE_SIZE}&page=${page}&usecaseId=${useCaseId}&plantId=${plantId}`;
    }
    try {
      const res = await axios.get(url);
      return res.data.rows;
    } catch (error) {
      console.error("Error fetching messages:", error);
      return [];
    }
  }
};

const getTotalPages = async (data: any) => {
  try {
    const useCaseId = data.payload?.useCaseId;
    const plantId = data.payload?.plantId;
    const assetId = data.payload?.assetId;
    const ticketId = data.payload?.ticketId;
    const created_on = data.payload?.created_on;
    let url = "";
    if (useCaseId !== undefined && plantId !== undefined) {
      //adding condition to fetch all messages for a specific ticket; as needed for CIP usecase
      if (checkEmpty(ticketId) && checkEmpty(created_on)) {
        url = `messages/filterMessagesByUsecasePlant?pageSize=${PAGE_SIZE}&usecaseId=${useCaseId}&plantId=${plantId}&assetId=${assetId}&ticketId=${ticketId}&created_on=${created_on}`;
      } else if (assetId !== "" && assetId !== undefined) {
        url = `messages/filterMessagesByUsecasePlant?usecaseId=${useCaseId}&plantId=${plantId}&assetId=${assetId}`;
      } else {
        url = `messages/filterMessagesByUsecasePlant?usecaseId=${useCaseId}&plantId=${plantId}`;
      }
      const res = await axios.get(url);
      return res.data.total;
    } else {
      return 0;
    }
  } catch (error) {
    console.error("Error fetching total pages:", error);
    return [];
  }
};

export const getMessagesByRole = async (
  fetchKey: any,
  fetchValue: any,
  fetchKey2: any,
  fetchValue2: any
) => {
  try {
    let page = 1;
    let res = null;
    let ResponseMessage: any = [];
    do {
      let url =
        fetchKey === "accessibleAssets"
          ? `messages/fileredMessagesByRole?${fetchKey}=${JSON.stringify(
              fetchValue
            )}&pageSize=${PAGE_SIZE}&page=${page++}`
          : `messages/fileredMessagesByRole?${fetchKey}=${JSON.stringify(
              fetchValue
            )}&${fetchKey2}=${JSON.stringify(
              fetchValue2
            )}&pageSize=${PAGE_SIZE}&page=${page++}`;
      res = await axios.get(url);
      ResponseMessage = ResponseMessage.concat(res.data.rows);
    } while (res.data.page < res.data.totalPages);
    return ResponseMessage;
  } catch (error) {
    console.error("Error fetching fileredMessagesByRole API:", error);
    return [];
  }
};

export const getAllMessages = async (payload: any) => {
  try {
    let assets: any = [];
    const totalRecords = await getTotalPages(payload);
    const totalPages = Math.ceil(totalRecords / PAGE_SIZE);

    // Create an array of page numbers from 1 to totalPages
    const pageNumbers = Array.from(
      { length: totalPages },
      (data: any, i: any) => i + 1
    );
    //[1,2,3]

    // Use Promise.all to fetch all pages concurrently
    const results = await Promise.all(
      pageNumbers.map((val) => fetchPage(val, payload))
    );
    // Concatenate the results from all pages
    assets = results.reduce((acc: any, rows: any) => acc.concat(rows), []);
    return assets;
  } catch (error) {
    console.error("Error fetching getAllMessages API:", error);
    return [];
  }
};

//function to get messages based on role and current filter
export const getMessagesByRoleAndPagination = async (searchValue: any) => {
  const messagesList = store.getState().messagesList;
  const userAccessResponse = store.getState().userAccess;
  try {
    let res = null;
    let baseURL = `messages/searchMessages`;
    let payload: any = {
      pageSize: messagesList.pagination.pageSize,
      page: messagesList.pagination.pageNo,
    };
    let queryArray: any = [];
    let userAccess = userAccessResponse?.userAccess;
    let UserprofileAccess = userAccess
      ? userAccess[0]?.profile_based_access
      : null;
    if (!UserprofileAccess?.isAdminRole) {
      if (UserprofileAccess?.asset_access?.assets?.length > 0) {
        payload["accessibleAssets"] = UserprofileAccess?.asset_access?.assets;
        payload["accessiblePlantsArray"] =
          UserprofileAccess?.asset_access?.accessible_asset_plants;
      }
      if (UserprofileAccess?.plant_access?.length > 0) {
        payload["accessiblePlantsArray"] = UserprofileAccess?.plant_access;
      }
      if (UserprofileAccess?.usecase_access?.length > 0) {
        payload["usecaseIds"] = UserprofileAccess?.usecase_access;
      }
    }
    if (Object.keys(searchValue).length !== 0) {
      for (let k in searchValue) {
        if (
          typeof searchValue[k] === "string" &&
          searchValue[k].trim() !== ""
        ) {
          payload = { ...payload, [k]: searchValue[k] };
          queryArray.push(k);
        }
      }
    }
    if (queryArray.length === 0) {
      payload = { ...payload, search: "" };
    }
    payload = { ...payload, sort: searchValue["sort"] };
    res = await axios.post(baseURL, payload);
    return {
      messagesRes: res?.data?.rows,
      messagesDataCount: res?.data?.total,
    };
  } catch (error) {
    console.error("Error fetching getMessagesByRoleAndPagination API:", error);
    return { messagesRes: [], messagesDataCount: 0 };
  }
};

//function to get all usecases from the api
const getMessages = async (data: any) => {
  let messages: any = [];
  const usersList = store.getState().usersList;
  const usecaseList = store.getState().usecaseList;
  const plantsList = store.getState().plantsList;
  const useCaseId = data.payload?.useCaseId;
  const plantId = data.payload?.plantId;
  if (
    usersList.currentUserPermissions.includes("ARCH_ADMIN") ||
    usersList.currentUserPermissions.includes("ARCH_DEVELOPER") ||
    usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER")
  ) {
    messages = await getAllMessages(data);
  } else {
    let usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
      return ele.includes("ARCH_USECASE_");
    });
    const plantRoles = usersList.currentUserPermissions.filter((ele: any) => {
      return ele.includes("ARCH_PM_");
    });
    //Plant manager role
    if (plantRoles.length > 0) {
      const accessiblePlantsArray = plantRoles
        .map((ele: any) => {
          return ele.substring(8);
        })
        .map((plantCode: any) => {
          return plantsList.plants.find((plant: any) => {
            return plant.plant_code === plantCode;
          });
        })
        .map((plant: any) => plant._id);
      let responseMessages = await getMessagesByRole(
        "accessiblePlantsArray",
        [plantId],
        "usecaseIds",
        [useCaseId]
      );
      messages = messages.concat(responseMessages);
    }
    //Asset Manager Role
    if (usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")) {
      const accessibleAssets = usersList.currentUser.allowed_assets;
      let responseMessages = await getMessagesByRole(
        "accessibleAssets",
        accessibleAssets,
        "",
        ""
      );
      messages = messages.concat(responseMessages);
    }
    //Usecase champion role

    if (usecaseRoles.length > 0) {
      let usecaseCodeName = usecaseRoles.map((usecaseName: any) => {
        return usecaseName.substring(13);
      });
      let usecaseIds = usecaseList.usecases
        .filter((usecaseData: any) => {
          if (usecaseCodeName.includes(usecaseData.level_use_code)) {
            return usecaseData.litmus_use_code;
          }
        })
        .map((data: any) => data._id);
      if (
        (plantRoles.length > 0 ||
          usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")) &&
        !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
      ) {
        messages = messages.filter((item: any) =>
          usecaseIds.includes(item.usecase_id)
        );
      } else if (
        !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL") &&
        plantRoles.length === 0 &&
        !usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
      ) {
        let responseMessages = await getMessagesByRole(
          "usecaseIds",
          [useCaseId],
          "accessiblePlantsArray",
          [plantId]
        );
        messages = messages.concat(responseMessages);
      } else if (
        usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
      ) {
        messages = await getAllMessages(data);
      }
    }
  }
  return messages;
};

//function to get single message
const getMessage = async (messageId: any) => {
  let message: any = [];
  let res = null;
  //loop to fetch all pages from api
  // const resMessage = await axios.get(`messages`);
  // await messagesCount();
  res = await axios.get(`messages/${messageId}`);

  message = res.data;
  return message;
};

// function to get messages based on user roles and filter
const getAllMessagesWithPagination = async (data: any) => {
  let messagesCount: any = 0;
  let messages: any = [];
  let response = await getMessagesByRoleAndPagination(data.payload);
  messages = response.messagesRes;
  messagesCount = response.messagesDataCount;
  return { messagesRes: messages, messagesDataCount: messagesCount };
};

export function* listMessages(payload: any): any {
  try {
    const data = yield call(() =>
      getMessages(payload).then((res) => {
        return res;
      })
    );
    if (data !== null) {
      yield put({
        type: LOAD_MESSAGES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_MESSAGES_ERROR,
      payload: null,
    });
  }
}

export function* addMessage(action: any): any {
  const messageList = store.getState().messagesList;
  try {
    const data = yield call(() =>
      getAllMessagesWithPagination({ payload: messageList.filterParams }).then(
        (res) => {
          return res;
        }
      )
    );
    if (data !== null) {
      yield put({
        type: LOAD_ALL_MESSAGES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: LOAD_ALL_MESSAGES_ERROR,
      payload: null,
    });
  }

  try {
    const data = yield call(() =>
      getMessage(action.payload).then((res) => {
        return res;
      })
    );
    yield put({
      type: ADD_MESSAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: ADD_MESSAGE_ERROR,
      payload: null,
    });
  }
}
// function to updating pagination data
export function* updatePagination(action: any): any {
  try {
    yield put({
      type: UPDATE_MESSAGE_PAGINATION_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: UPDATE_MESSAGE_PAGINATION_ERROR,
      payload: action.payload,
    });
  }
}
//function to fetch all messages
export function* filteredMessages(params: any): any {
  try {
    const data = yield call(() =>
      getAllMessagesWithPagination(params).then((res) => {
        return res;
      })
    );
    if (data !== null) {
      yield put({
        type: LOAD_ALL_MESSAGES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: LOAD_ALL_MESSAGES_ERROR,
      payload: null,
    });
  }
}
export function* updateMessagesFilterParams(params: any): any {
  try {
    yield put({
      type: UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    yield put({
      type: UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS,
      payload: null,
    });
  }
}
export function* listMessagesSaga() {
  yield takeEvery(LOAD_MESSAGES, listMessages);
  yield takeEvery(ADD_MESSAGE, addMessage);
  yield takeEvery(UPDATE_MESSAGE_PAGINATION, updatePagination);
  yield takeEvery(LOAD_ALL_MESSAGES, filteredMessages);
  yield takeEvery(UPDATE_MESSAGE_FILTER_PARAMS, updateMessagesFilterParams);
}
