/**
 * Not to be imported elsewhere
 */

/**
 * Overrides console object accessible to Javascript
 */
export function disableConsoleLogs() {
  console.debug = () => {};
  console.error = () => {};
  console.info = () => {};
  console.log = () => {};
  console.warn = () => {};
  console.dir = () => {};
  console.dirxml = () => {};
  console.table = () => {};
  console.trace = () => {};
  console.group = () => {};
  console.groupCollapsed = () => {};
  console.groupEnd = () => {};
  console.clear = () => {};
  console.count = () => {};
  console.countReset = () => {};
  console.assert = () => {};
  console.profile = () => {};
  console.profileEnd = () => {};
  console.time = () => {};
  console.timeLog = () => {};
  console.timeEnd = () => {};
  console.timeStamp = () => {};
}

/**
 * Disables context menu on the application
 */
export function disableContextMenu() {
  document.addEventListener("contextmenu", (e) => e.preventDefault());
}

/**
 * Disables hot keys to trigger devtools on the application
 * Hot keys as follows
 * F12: Launch Devtool
 * Ctrl + Shift + I
 * Ctrl + Shift + J
 * Ctrl + Shift + C
 * Ctrl + U
 */
export function disableHotKeys() {
  document.onkeydown = (e) => {
    if (
      e.key === "F12" ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      ctrlShiftKey(e, "i") ||
      ctrlShiftKey(e, "j") ||
      ctrlShiftKey(e, "c") ||
      (e.ctrlKey && e.key.toLocaleUpperCase() === "U")
    ) {
      return false;
    }
  };
}

/**
 * Checks the combination Ctrl + Shift are pressed together with Key or not
 * @param e: Key Event
 * @param key: Key Pressed
 * @returns boolean
 */
function ctrlShiftKey(e: any, key: string) {
  return e.ctrlKey && e.shiftKey && e.key === key;
}

/**
 * TODO: Handle 40x 50x console logs (Browser Native)
 */
export function disable40XErrorConsoleLogs() {}

const localPen: Console = Object.assign({}, console);
/**
 * TODO: Get legal message from business to warn user about IT polices
 */
export function legalDevtoolWarning() {
  localPen.log();
}
