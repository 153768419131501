export const defaultHeaderAlign = "left";

export const defaultOddRowColor = "#2D376D";

export const defaultEvenRowColor = "#242C59";

export const defaultPage = 0;

export const defaultRowsPerPage = 5;

export const defaultRowsPerPageOptions = [5, 10, 25, 50]

export const defaultTableLengthCount =  0

export const defaultExportCsvName = "data-table.xlsx"

export const alternativeStyle = (index: number, color: string | undefined): any => {
    if (index % 2 === 0) {
        return {
            backgroundColor: color || defaultEvenRowColor
        };
    } else {
        return {
            backgroundColor: color || defaultOddRowColor
        };
    }
};