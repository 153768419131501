import {
  LOAD_CHATROOMS_SUCCESS,
  LOAD_CHATROOMS_ERROR,
  UPDATE_CHATROOMS_DELETE,
  LOAD_CHATROOMS_BY_ID,
} from "../Types/chatroom.contants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import store from "../store";
const PAGE_SIZE = 500;

const getTotalPagesForChatRoomID = async (chatRoomId: string) => {
  try {
    const res = await axios.get(`chatrooms/${chatRoomId}`);
    return res.data.messages.length;
  } catch (error) {
    console.error("Error fetching total pages:", error);
    return [];
  }
};

const fetchPagesForChatRoomID = async (page: any, chatRoomId: string) => {
  try {
    const res = await axios.get(
      `chatrooms/${chatRoomId}?pageSize=${PAGE_SIZE}&page=${page}`
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching chatrooms:", error);
    return [];
  }
};

const getChatroomsDataForChatRoomID = async (chatRoomID: string) => {
  let chatrooms: any = [];

  const totalRecords = await getTotalPagesForChatRoomID(chatRoomID);
  const totalPages = Math.ceil(totalRecords / PAGE_SIZE);

  // Create an array of page numbers from 1 to totalPages
  const pageNumbers = Array.from(
    { length: totalPages },
    (data: any, i: any) => i + 1
  );

  // Use Promise.all to fetch all pages concurrently
  const results = await Promise.all(
    pageNumbers.map((pgNo: any) => fetchPagesForChatRoomID(pgNo, chatRoomID))
  );
  // Concatenate the results from all pages
  chatrooms = results.reduce((acc: any, rows: any) => acc.concat(rows), []);
  return chatrooms;
};

export function* listChatroomsForChatRoomID(payload: any): any {
  if (payload["msgID"] != undefined) {
    let currStore = store.getState();
    let chatroomList = currStore.chatroomList.chatrooms;
    let chatroom = undefined;
    for (let i = 0; i < chatroomList.length; i++) {
      if (chatroomList[i]["_id"] === payload["chatroomID"]) {
        chatroom = chatroomList[i];
        chatroomList.splice(i, 1);
        break;
      }
    }
    if (chatroom) {
      for (let i = 0; i < chatroom.messages.length; i++) {
        if (chatroom.messages[i]["_id"] === payload["msgID"]) {
          chatroom.messages.splice(i, 1);
          break;
        }
      }
      chatroomList.push(chatroom);
      yield put({
        type: LOAD_CHATROOMS_SUCCESS,
        payload: chatroomList,
      });
      return;
    }
  }
  try {
    const data = yield call(() =>
      getChatroomsDataForChatRoomID(payload.chatRoomID).then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_CHATROOMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_CHATROOMS_ERROR,
      payload: null,
    });
  }
}
export function* listChatroomSaga() {
  yield takeLatest(LOAD_CHATROOMS_BY_ID, listChatroomsForChatRoomID);
  yield takeEvery(UPDATE_CHATROOMS_DELETE, listChatroomsForChatRoomID);
}
