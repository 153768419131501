import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App"; //entry point for the actual app
import "font-awesome/css/font-awesome.min.css";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { HelmetProvider } from "react-helmet-async";
import { SidebarProvider } from "./contexts/SidebarContext";
import { msConfig } from "./Services/authService";
import { MsalProvider } from "@azure/msal-react";
import AuthAD from "./Auth";
import { env } from "./env";
import * as devtool from "./devtools";
import OEMSignOffPage from "./pages/master/UsecaseDashboard/SATSignOff/OEMDocUploadPage";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(env.eam_syncfusion_license);

const DEBUG = env.environment === "DEV";

/**
 * Debug configuration
 */
if (!DEBUG) {
  devtool.disableConsoleLogs();
  devtool.disableContextMenu();
  devtool.disableHotKeys();
  devtool.legalDevtoolWarning();
  // devtool.disable40XErrorConsoleLogs();
}

const urlSegments = window.location.hash.split("/");
const url = urlSegments.slice(1, urlSegments.length).join("/");
ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <HashRouter>
          {url.includes("sat/upload-docs/") ? (
            <OEMSignOffPage />
          ) : (
            <MsalProvider instance={msConfig}>
              <AuthAD>
                <App />
              </AuthAD>
            </MsalProvider>
          )}
        </HashRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
