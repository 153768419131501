import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function AvailablePlants() {
  const usersList = useSelector((state: any) => state.usersList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const userAccessResponse = useSelector((state: any) => state.userAccess);
  const [availablePlants, setAvailablePlants]: any = React.useState([]);
  let userAccess = userAccessResponse?.userAccess;
  let UserprofileAccess = userAccess
    ? userAccess[0]?.profile_based_access
    : null;
  let isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null;
  useEffect(() => {
    if (
      plantsList.plants.length > 0 &&
      usersList.scmUserPlantGroup &&
      usersList.scmUserPlantGroup.length > 0 &&
      isAdminRole !== null &&
      !isAdminRole
    ) {
      setAvailablePlants(
        plantsList.plants.filter((plant: any) =>
          usersList.scmUserPlantGroup.includes(plant._id)
        )
      );
    } else {
      setAvailablePlants(usersList.accessiblePlants);
    }
  }, []);

  return availablePlants;
}

export default AvailablePlants;
